import * as React from 'react'
import { API_USERS } from 'constants/api-v2'
import { urlAddParams } from 'services/url'
import BaseAsyncSelect from 'components/Filters/BaseAsyncSelect'
import type { BaseSelectProps } from 'components/UI/SelectComponents'
import type { BaseAsyncSelectProps } from 'components/Filters/BaseAsyncSelect'

interface UserProps {
  id: number
  firstName: string
  lastName: string
  reference: string
  enabled: boolean
  organization: {
    internal: boolean
  }
}

interface UsersProps {
  queryParams?: any
  withIcons?: boolean
}

const svgIcon = (props: UserProps) => {
  const base = props.enabled ? 'Actif' : 'Inactif'
  const ext = props.organization.internal ? 'INT' : 'EXT'
  return `${base}-${ext}.svg`
}

export const userMapper = (user: UserProps) => ({
  ...user,
  label: `${user.lastName.toUpperCase()} ${user.firstName} (${user.reference})`,
  value: user.id,
})

export const iconsStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    padding: '8px 4px',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ':before': {
      content: `url(/images/${svgIcon(state.data)})`,
      width: '26px',
      height: '26px',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  }),
}

export default function Users({
  queryParams = {},
  withIcons = true,
  ...props
}: BaseSelectProps & BaseAsyncSelectProps & UsersProps) {
  const stylesProp = !!withIcons ? { styles: iconsStyles } : {}

  return (
    <BaseAsyncSelect
      isClearable
      name="users"
      label="label.user"
      url={urlAddParams(API_USERS, queryParams)}
      mapper={userMapper}
      {...stylesProp}
      apiPlatform
      {...props}
    />
  )
}
